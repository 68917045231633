.container {
}

.paymentSafety {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 140px;

  background: #FFFFFF;
  box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.stripeImg {
  width: 130px;
  height: 48px;
}

.actionButtonContainer {
  align-self: stretch;
}

.cardContainer {
  box-sizing: border-box;
  padding: 16px;
  border: 1.5px solid #CFCFCF;
  border-radius: 10px;
  margin-bottom: 8px;
  cursor: pointer;
  position: relative;
}

.cardContainerSelected {
  border: 1.5px solid #422680;
}

.cardContainerUnavailable {
  border: 1.5px solid #CFCFCF;
  cursor: inherit;
}

.cardOptionPrice {
  position: absolute;
  top: 16px;
  right: 64px;
}

.cardOptionTick {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}

.paymentDetailsCard {
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

/* Mobile */
@media screen and (max-width: 470px) {
  .paymentSafety {
    padding: 32px 50px;
  }
  
  .actionButtonContainer {
  }
}