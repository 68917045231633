.location-option-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.location-option-container.vertical {
  flex-direction: column;
  margin-left: 0px;
  text-align: center;
  width: 100%;
}

.location-option-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #280659;

  width: 70%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 8px;
  text-overflow: ellipsis;
}

.location-option-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 30px;
}

.search-form-location-text-placeholder {
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 0px;
  margin-right: 8px;
  height: 23px;
  align-self: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #c8c8c8;
}

.location-option-price-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  color: #000000;
  margin-top: 16px;
  margin-bottom: 16px;
}

.location-option-price-label.purple {
  color: #422680;
}

@media screen and (max-width: 470px) {
  .location-option-text {
    font-size: 18px;
    line-height: 21px;
    width: 65%;
  }
}
