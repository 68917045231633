.container {
  position: absolute;

  padding: 10px 0px;
  display: flex;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  cursor: pointer;
}

.contentRoundedLeft {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.contentRoundedRight {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.edge {
  width: 10px;
}

.middle {
  flex: 1;
  padding: 8px;
  position: relative;
}

.middleOverlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoRow {
  display: flex;
  flex-direction: row;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  color: #280659;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-bottom: 8px;
}

.infoText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}