.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pageBlock {
  width: 20px;
  height: 25px;
  cursor: pointer;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.pageBlockUnclickable {
  composes: pageBlock;
  pointer-events: none;
}

.arrow {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowReverse {
  composes: arrow;
  transform: rotate(180deg);
}

.arrowDisabled {
  opacity: 0.2;
  pointer-events: none;
}