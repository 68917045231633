.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.containerBlocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.centeredHalfWidth {
  width: 50%;
}

@media screen and (max-width: 470px) {
  .centeredHalfWidth {
    width: 95%;
  }
  
  .center {
    width: 80%;
    margin: 0 auto;
  }
}