.carContainer {
  display: flex;
  margin-top: -2rem;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.modelAndRegistration {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 2rem;

  font-weight: 400;
  font-size: 25px;
  line-height: 29px;

  color: #280659;
}

.dataContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dataCell {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  height: 100%;

  margin-top: 1rem;
}

.dataCellTitles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #280659;
}

.title {
  /* line-height: 21px; */

  margin-top: 1rem;
}

.totalIncome {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #280659;
}

.redColor {
  color: #f54952;
}

.greenColor {
  color: #60c72e;
}

.statisticsText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;

  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
