/*------------------------------------
- COLOR brandColor
------------------------------------*/
.alert-brandColor {
  color: #0a0615;
  background-color: #9f87da;
  border-color: #9277d5;
}

.alert-brandColor hr {
  border-top-color: #8364cf;
}

.alert-brandColor .alert-link {
  color: #000000;
}

.badge-brandColor {
  color: #fff;
  background-color: #3a2373;
}

.badge-brandColor[href]:hover,
.badge-brandColor[href]:focus {
  color: #fff;
  background-color: #26174b;
}

.bg-brandColor {
  background-color: #3a2373 !important;
}

a.bg-brandColor:hover,
a.bg-brandColor:focus,
button.bg-brandColor:hover,
button.bg-brandColor:focus {
  background-color: #26174b !important;
}

.border-brandColor {
  border-color: #3a2373 !important;
}

.btn-brandColor {
  color: #fff;
  background-color: #3a2373;
  border-color: #3a2373;
}

.btn-brandColor:hover {
  color: #fff;
  background-color: #2c1a57;
  border-color: #26174b;
}

.btn-brandColor:focus,
.btn-brandColor.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 35, 115, 0.5);
}

.btn-brandColor.disabled,
.btn-brandColor:disabled {
  color: #fff;
  background-color: #3a2373;
  border-color: #3a2373;
}

.btn-brandColor:not(:disabled):not(.disabled):active,
.btn-brandColor:not(:disabled):not(.disabled).active,
.show > .btn-brandColor.dropdown-toggle {
  color: #fff;
  background-color: #26174b;
  border-color: #201340;
}

.btn-brandColor:not(:disabled):not(.disabled):active:focus,
.btn-brandColor:not(:disabled):not(.disabled).active:focus,
.show > .btn-brandColor.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 35, 115, 0.5);
}

.btn-outline-brandColor {
  color: #3a2373;
  background-color: transparent;
  border-color: #3a2373;
}

.btn-outline-brandColor:hover {
  color: #fff;
  background-color: #3a2373;
  border-color: #3a2373;
}

.btn-outline-brandColor:focus,
.btn-outline-brandColor.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 35, 115, 0.5);
}

.btn-outline-brandColor.disabled,
.btn-outline-brandColor:disabled {
  color: #3a2373;
  background-color: transparent;
}

.btn-outline-brandColor:not(:disabled):not(.disabled):active,
.btn-outline-brandColor:not(:disabled):not(.disabled).active,
.show > .btn-outline-brandColor.dropdown-toggle {
  color: #fff;
  background-color: #3a2373;
  border-color: #3a2373;
}

.btn-outline-brandColor:not(:disabled):not(.disabled):active:focus,
.btn-outline-brandColor:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-brandColor.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 35, 115, 0.5);
}

.list-group-item-brandColor {
  color: #0a0615;
  background-color: #9277d5;
}

.list-group-item-brandColor.list-group-item-action:hover,
.list-group-item-brandColor.list-group-item-action:focus {
  color: #0a0615;
  background-color: #8364cf;
}

.list-group-item-brandColor.list-group-item-action.active {
  color: #fff;
  background-color: #0a0615;
  border-color: #0a0615;
}

.table-brandColor,
.table-brandColor > th,
.table-brandColor > td {
  background-color: #9277d5;
}

.table-hover .table-brandColor:hover {
  background-color: #8364cf;
}

.table-hover .table-brandColor:hover > td,
.table-hover .table-brandColor:hover > th {
  background-color: #8364cf;
}

.text-brandColor {
  color: #3a2373 !important;
}

a.text-brandColor:hover,
a.text-brandColor:focus {
  color: #26174b !important;
}

input:focus {
  outline: none
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}