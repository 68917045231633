.header {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.halfWidth {
  width: 50%;
}

.guaranteeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.guaranteeCheck {
  min-width: 130px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inputFieldWidth {
  width: 106px;
  margin-right: 8px;
  margin-left: 16px;
}

.detailsAndExtraOptionsContainer {
  display: flex;
}

.halfPane {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.saveButtonContainer {
  display: flex;
  flex-direction: row-reverse;
}

.detailsLabel {
  width: 220px;
}

.basePriceGuaranteeLabel {
  width: 180px;
}