.date-picker-modal-week-container {
  height: 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.date-picker-modal-week-cell {
  height: 34px;
  width: 34px;
  border-radius: 100px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  line-height: 18px;
  font-weight: normal;
  color: rgb(113, 113, 113);
}

.date-picker-modal-week-cell.date {
  border: 1px solid white;
  cursor: pointer;
  color: #280659;
}

.date-picker-modal-week-cell.date:hover {
  border: 1px solid #280659;
}

.date-picker-modal-week-cell.selected {
  border: 1px solid #280659;
  background-color: #280659;
  color: white;
}

.date-picker-modal-week-cell.disabled {
  color: grey;
  cursor: default;
  opacity: 0.5;
}
