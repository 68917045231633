.mainContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 140px;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.05);
  padding: 16px 32px 16px 32px;
  border-radius: 10px;
  margin-left: 20px;
}

.clickable {
  cursor: pointer;
}

.textContainer {
  display: flex;
  flex-direction: row;
}

.verticalContainer {
  width: 165px;
  display: flex;
  flex-direction: column;
}

.verticalContainerName {
  width: 215px;
  display: flex;
  flex-direction: column;
}

.horizontalContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.smallImage {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.titleLabel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;

  color: #280659;
}

.carLabel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
  cursor: pointer;

  color: #280659;
}

.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: #280659;
}

.labelBold {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0px;

  color: #280659;
}

.detailsContainer {
  width: 400px;
  height: 74px;
}

.carContainer {
  width: 170px;
  height: 74px;
}

.imageContainer {
  width: 84px;
  height: 84px;
  border-radius: 10px;
  object-fit: cover;
}