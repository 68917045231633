.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 980px;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 32px;
}

.statsContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.statContainer {
  display: flex;
  width: 170px;
  flex-direction: column;
}

.imgContainer {
  width: 14px;
  height: 14px;
  margin-left: 8px;
}

.rowContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.rowContainerMonth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  height: 48px;
  padding: 16px 32px 16px 32px;
  margin-top: 16px;
}

.widthContainer {
  width: 140px;
}

.invoiceStatusContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 120px;
}