.container {
  width: 90%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(245, 73, 82, 0.3);
  align-self: center;
}

.fullWidth {
  width: 100%;
}