.nav-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 56px;
  background: #fbfbfb;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.05);
  z-index: 10000;
  margin-bottom: 0px;
}

.n-container {
  width: 1360px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
}

.nav-logo {
  cursor: pointer;
}

.nav-items {
  display: flex;
  text-decoration: none;
  font-family: Roboto;
  font-style: normal;
  color: #280659;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
}

.nav-item {
  color: #280659;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  margin-right: 32px;
}

.nav-item:last-child {
  margin-right: 0px;
}

.navbar {
  background-color: #333;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
  overflow: hidden;
}

.dropdown .dropbtn {
  color: #280659;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  border: none;
  outline: none;
  color: #280659;
  background-color: inherit;
  font-family: inherit;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 130px;
  z-index: 101;
}

.dropdown-content a {
  float: none;
  color: #280659;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
  text-decoration: none;
  color: #280659;
}

.dropdown:hover .dropdown-content {
  display: block;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.dropdown:hover {
  overflow: visible;
}

.navbarDropdownArrow {
  height: 15px;
  margin-right: 10px;
}

@media screen and (max-width: 470px) {
  .nav-container {
    position: relative;
  }

  .nav-menu {
    position: absolute;
    left: -100%;
    opacity: 1;
  }

  .nav-menu.active {
    background-color: #fbfbfb;
    box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.05);
    position: absolute;
    width: 100%;
    left: 0;
    top: 56px;
    opacity: 1;
    animation: nav-menu-animation 0.3s linear forwards;
    color: #280659;
    border-radius: 0px 0px 10px 10px;
    z-index: 100;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding: 0 10px 0 15px;
  }

  .nav-item {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .locationsHeadlineText {
    font-weight: bold;
  }
}

@keyframes nav-menu-animation {
  from {
    transform: translateY(-400px);
  }

  to {
    transform: translateY(0);
  }
}
