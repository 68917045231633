.search-form-car-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-form-car-input-section {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
}

.search-form-car-input-section.first {
  margin-top: 0px;
}

.search-form-car-input-section.last {
  margin-bottom: 0px;
}

.search-form-car-input-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(245, 73, 82, 0.3);
}

@media screen and (max-width: 470px) {
  .search-form-car-input-section {
    margin-top: 8px;
    margin-bottom: 16px;
  }
}
