.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container:last-child {
  margin-bottom: 0px;
}

.textContainer {
  display: inline;
  font-size: 14px;
}

@media screen and (max-width: 470px) {
  .thumbnail {
    width: 100%;
    margin-right: 0px;
    height: auto;
    aspect-ratio: 2;
    margin-top: 16px;
  }
  
  .textContainer {
    width: 100%;
    display: block
  }

  .centerTextOnMobile {
    text-align: center;
  }
}
