.selectContainer {
  display: flex;
  flex-direction: row;
  margin-right: 4rem;
}

.labelInputSelect {
  height: 21px;
  left: 32px;
  top: 110px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  align-self: center;

  color: #280659;
}

.container {
  background: #ffffff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  position: relative;

  white-space: nowrap;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;

  padding-top: 2rem;
}

.carDetailsHead {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  width: 100%;

  padding-top: 2rem;
}

.dataCell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dataCellText {
  color: #280659;

  height: 21px;
  left: 206px;
  top: 86px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-right: 0.5rem;
}

.statisticsText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;

  height: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;

  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.redColor {
  color: #f54952;
}

.greenColor {
  color: #60c72e;
}

.carDataCell {
  display: flex;
  flex-direction: column;
}

.carName {
  left: 32px;
  top: 80px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #280659;
}

.registrationNumber {
  font-weight: 700;
}

@media screen and (max-width: 470px) {
  .dropdowns {
    flex-direction: column;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .selectContainer {
    margin-right: 0px;
    margin-bottom: 1rem;
  }

  .container {
    flex-direction: column;
  }

  .row {
    flex-direction: column;
  }

  .carDetailsHead {
    flex-direction: column;
  }
}
