.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 140px;
}

.dayOfTheWeek {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 8px;

  color: #000000;
}

.date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 0px;

  color: #000000;
}

.badge {
  width: 5px;
  height: 50px;
  margin-right: 8px;
}

.badgeSelected {
  width: 5px;
  height: 50px;
  margin-right: 8px;
  background: #60C72E;
}

.badgeNormal {
  width: 5px;
  height: 50px;
  margin-right: 8px;
  background: #422680;
}