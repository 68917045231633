.support-phone-fixed-bottom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 32px;
  z-index: 100;
  bottom: 0;
  width: 100%;
}

.support-phone-fixed-bottom-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #422680;
  border-radius: 10px 10px 0px 0px;
  height: 32px;
  padding-top: 8px;
  width: 230px;
}

.support-phone-label {
  color: white;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
  text-decoration: none;
}

.support-phone-label:hover {
  color: white;
}
