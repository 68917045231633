.container {
  width: 500px;

  background: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 32px
}

.title {

}

.edit {
  width: 70%;
  margin: 0 auto;
}