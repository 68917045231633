.content {
  display: flex;
  flex-direction: row;
}

.car {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px 0px;
}

.carThumbnail {
  width: 85px;
  height: 64px;
  border-radius: 10px;
  margin-top: 8px;
  object-fit: cover;
}

.invisibleCar {
  visibility: hidden;
  pointer-events: none;
}