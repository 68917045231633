@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0, 0%, 13%;

    --muted: 0, 0%, 44%;
    --muted-foreground: 0 0% 100%;

    --popover: 0, 0%, 92%;
    --popover-foreground: 0 0% 100%;

    --card: 0, 0%, 97%;
    --card-foreground: 0, 0%, 13%;

    --border: 0, 0%, 84%;
    --input: 0, 0%, 84%;

    --primary: 259, 54%, 33%;
    --primary-foreground: 0 0% 100%;

    --secondary: 0, 0%, 13%;
    --secondary-foreground: 0 0% 100%;

    --accent: 220 14.3% 95.9%;
    --accent-foreground: 0 0% 100%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 0 0% 100%;

    --ring: 259, 54%, 33%;

    --radius: 0.5rem;
  }

  /* .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  } */
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background !text-foreground;
    font-family: sans-serif;
  }
}