.container {
  width: 1140px;
  margin: 0 auto;
}

/* Mobile */
@media screen and (max-width: 470px) {
  .container {
    width: 100%;
  }
}