.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;

  color: #280659;
}

@media screen and (max-width: 470px) {
  .centerOnMobile {
    text-align: center;
  }
}
