.mainContainer {
  display: flex;
  width: 108px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 8px;

  color: #280659;
}