.login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 48px;
  margin-bottom: 48px;
}

.login-inner-container {
  width: 1360px;
  height: 680px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
}

.login-image-container {
  width: 850px;
  height: 680px;
  border-radius: 10px 0px 0px 10px;
  position: relative;
  display: flex;
}

.login-image {
  width: 850px;
  height: 680px;
  object-fit: cover;
  border-radius: 10px 0px 0px 10px;
}

.login-image-driver {
  content: url('../../assets/login/background.png');
}

.login-image-owner {
  content: url('../../assets/login/background-owner.png');
}

.login-image-inner-container {
  position: absolute;
  top: 32px;
  left: 32px;
  width: 786px;
  height: 616px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.login-image-logo {
  width: 180px;
  height: 110px;
  margin-bottom: 48px;
  content: url('../../assets/login/white-logo.svg');
}

.login-big-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 90px;
  line-height: 105px;
  margin-bottom: 0px;
  color: #ffffff;
}

.login-big-text.bold {
  font-weight: 800;
}

.login-small-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;

  align-self: center;
  margin-top: 236px;
}

.login-details-container {
  width: 510px;
  height: 680px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
}

@media screen and (max-width: 470px) {
  .login-container {
    display: flex;
    margin-top: 0px;
    margin-bottom: 320px;
  }

  .login-inner-container {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0px;
  }

  .login-image-container {
    width: 100%;
    height: auto;
    border-radius: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0px;
  }

  .login-image-driver {
    content: url('../../assets/login/mobile-background.png');
  }
  
  .login-image-owner {
    content: url('../../assets/login/mobile-background-owner.png');
  }

  .login-image-inner-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 400px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .login-image-logo {
    width: 104px;
    height: 64px;
    margin-bottom: 24px;
    content: url('../../assets/login/white-logo.svg');
  }

  .login-big-text {
    font-size: 60px;
    line-height: 72px;
  }

  .login-small-text {
    margin-top: 32px;
  }

  .login-details-container {
    position: absolute;
    top: 320px;
    width: 96%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
  }
}
