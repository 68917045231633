.mainContainer {
  display: flex;
  width: 100%;
  margin-left: 16px;
  flex-direction: column;
}

.eventContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

