.wrapper {
  display: flex;
  flex-direction: column;
}

.container {
  width: 300px;
  margin-right: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px;
}

.logOutContainer {
  composes: container;
  padding-top: 16px;
  padding-bottom: 16px;
}

.labelContainer {
  margin-top: 32px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer
}

.labelContainer:first-child {
  margin-top: 0px;
}

.labelIcon {
  margin-bottom: 8px;
  height: 24px;
  width: 24px;
}

.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #280659;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 0px;
}

.red {
  composes: label;
  color: #f54952;
}

.selected {
  font-weight: bold;
}

.profileImage {
  width: 179px;
  aspect-ratio: 1;
}

.disabled {
  cursor: default;
  opacity: 0.5;
}

@media screen and (max-width: 470px) {
  .wrapper {
    width: 95%;
    margin-right: 0px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .container {
    width: 95%;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 16px;
  }
}
  