.onboarding-veriff-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.onboarding-veriff-input-container {
  width: 300px;
}

@media screen and (max-width: 470px) {
  .onboarding-veriff-container {
    width: 100%;
  }

  .onboarding-veriff-input-container {
    width: 100%;
    align-self: flex-start;
  }
}
