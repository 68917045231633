.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  box-shadow: 0px 20px 15px -20px rgba(0, 0, 0, 0.05);
  background: #FFFFFF;
  padding-bottom: 16px;
}

.headerLeft {
  width: 150px;
}

.headerRight {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
}

.dateCell {
  display: flex;
  flex-direction: column;
  align-items: center;
}