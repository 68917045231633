.centeredHalfWidth {
  width: 50%;
  margin: 0 auto;
}

.center {
  width: 40%;
  margin: 0 auto;
}

@media screen and (max-width: 470px) {
  .centeredHalfWidth {
    width: 95%;
  }
  
  .center {
    width: 80%;
    margin: 0 auto;
  }
}