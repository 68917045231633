@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  src: url(./Roboto/Roboto-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url(./Roboto/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url(./Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url(./Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url(./Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: url(./Roboto/Roboto-Black.ttf) format('truetype');
}
