.arrow {
  margin: 0px 16px 0px 16px;
} 

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* Mobile */
@media screen and (max-width: 470px) {
  .arrow {
    margin: 0px 8px 0px 8px;
  } 
  
  .row {
    align-items: flex-end;
  }
}