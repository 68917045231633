.onboarding-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.onboarding-width-container {
  width: 1360px;
  margin-top: 48px;
  margin-bottom: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 60px 70px 60px 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.onboarding-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.onboarding-logo {
  width: 40px;
  height: 40px;
}

.onboarding-logo-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #280759;
  margin-bottom: 4px;
  margin-left: 16px;
}

.onboarding-skip-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #280659;
  align-self: flex-end;
  margin-bottom: 0px;
  cursor: pointer;
}

@media screen and (max-width: 470px) {
  .onboarding-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .onboarding-width-container {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 32px 16px 32px 16px;
  }
}
