.custom-date-picker-container {
  width: 201px;
  height: 23px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.custom-date-picker-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 0px;
  margin-right: 16px;
  cursor: pointer;
  color: #c8c8c8;
}

.custom-date-picker-date-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 0px;
  margin-right: 16px;
  cursor: pointer;
  color: #280659;
}

@media screen and (max-width: 470px) {
  .custom-date-picker-date-label {
    font-size: 18px;
    line-height: 21px;
    margin-right: 4px;
  }

  .custom-date-picker-label {
    font-size: 18px;
    line-height: 21px;
    margin-right: 6px;
  }
}
