.container {
  position: relative;
}

.deleteButton {
  display: flex;
  align-items: center;
  width: 100px;
}

.guaranteeName {
  width: 190px;
}

.numericInput {
  max-width: 90px;
}

.discountInput {
  max-width: 80px;
  margin-right: 8px;
}