.fixedLocation {
  width: 980px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px;

  background: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.fixedLocationContainer {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  width: 428px;
}

.mapContainer {
  display: flex;
  flex-direction: column;
  width: 456px;
}

.map {
  width: 456px;
  height: 163px;
  background-color: grey;
}

.inputField {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headerText {
  color: #280659;
  margin-bottom: 16px;
}

.tooltipText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  align-self: flex-start;
  color: #280659;
  margin-bottom: 16px;
}

.modalContainer {
  width: 380px;
  background-color: white;
  border-radius: 10px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.alignRight {
  align-self: flex-end;
}

.mapPin {
  position: relative;
  top: -40px;
  left: -20px;
  height: 40px;
  width: 40px;
}
