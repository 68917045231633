.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.innerContainer {
  width: 1360px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 48px;
  margin-top: 48px;
}

.mainContainer {
  position: relative;
  width: 1044px;
  margin-bottom: 16px;
  padding: 32px;
  padding-bottom: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

@media screen and (max-width: 470px) {
  .titleContainer {
    display: none;
  }

  .innerContainer {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }

  .mainContainer {
    width: 95%;
    margin-bottom: 16px;
    padding: 0px;
    background: transparent;
    box-shadow: none;
  }
}
  