.container {
  display: flex;
  flex-direction: row;
}

.tabItem {
  padding: 9px 16px;
  cursor: pointer;
  border-bottom: 4px solid #D9D9D9;
  min-width: 170px;
  display: flex;
  justify-content: center;
}

.activeTabItem {
  border-bottom: 4px solid #422680;
}
