.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.col {

}

.item {
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.image {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000
}

/* Mobile */
@media screen and (max-width: 470px) {
  .description {
    font-size: 16px;
  }

  .image {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}