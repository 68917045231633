.footer-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 16px;
  padding-top: 48px;
}

.footer-route {
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  text-decoration: none;
  margin-bottom: 8px;
}

.footer-route.bold {
  font-weight: bold;
}

.footer-route.red {
  color: #f54952;
}

.footer-route.purple {
  color: #280659;
}

.footer-route.left {
  text-align: left;
}

.footer-route.right {
  text-align: right;
}

.footer-routes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  justify-content: space-around;
  width: 1360px;
  margin-bottom: 48px;
}

.socials-section {
  width: 1360px;
  height: 79px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbfbfb;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.socials-logo {
  width: 38px;
  height: 38px;
  margin-right: 10px;
  margin-left: 10px;
}

.socials-container {
  display: flex;
  text-align: center;
  justify-content: space-between;
  width: 471px;
  height: 40px;
}

.crafted-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 35px;
}

.crafted-with {
  height: 21px;
  font-family: Roboto;
  margin-top: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #594090;
}

.all-rights {
  width: 1360px;
  height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: #422680;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-top: 35px;
  margin-bottom: 35px;
}

.divider {
  display: none;
}

@media screen and (max-width: 470px) {
  .footer-container {
    padding-bottom: 16px;
    padding-top: 24px;
  }

  .footer-route {
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    text-decoration: none;
    margin-bottom: 16px;
  }

  .footer-route.left {
    text-align: center;
  }

  .footer-route.right {
    text-align: center;
  }

  .footer-routes {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
  }

  .socials-section {
    height: 126px;
    width: 100%;
    text-align: center;
  }

  .socials-logo {
    width: 30px;
    height: 30px;
  }

  .socials-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 200px;
    height: 80px;
  }

  .crafted-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 35px;
  }

  .divider {
    display: flex;
    width: 314px;
    height: 0px;
    border: 1px solid rgba(245, 73, 82, 0.3);
    margin-bottom: 24px;
  }

  .all-rights {
    width: 100%;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    margin-top: 19px;
    margin-bottom: 16px;
  }
}
