.Modal {
  position: absolute;
  top: 40%;
  left: 50%;
  display: block;
  padding: 2em;
  min-width: 20em;
  max-width: 70%;
  background-color: #fff;
  border-radius: 1em;
  transform: translate(-50%, -50%);
  outline: transparent;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,.4);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}