.inputFieldContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding-left: 16px;
  border: 1px solid #280659;
  box-sizing: border-box;
  border-radius: 100px;
  padding-block: 4px;
  position: relative;
  background-color: #ffffff;
}

.innerContainer {
  position: relative;
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
}

.selectContainer {
  outline:0px;
  border:0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  width: 100%;
  padding-left: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background-color: #ffffff;
  color: #280659
}

.boldSelectedFont {
  font-weight: 700;
}

.selectLabel {
  min-width: 50px;
  height: 19px;
  left: 18px;
  top: 9px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #280659;
}

.dropdownIcon {
  position: absolute;
  left: 0px;
  top: 3px;
  pointer-events: none;
}