.contentCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.halfWidth {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saveBtn {
  width: 300px;
  justify-content: center;
  align-self: center;
}