.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 12px;
  gap: 8px;

  background: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 100px;

  cursor: pointer;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #280659;

  user-select: none;
}

.rotate {
  transform: rotate(180deg);
}

.dates {
  margin: 0 10px; 
}
