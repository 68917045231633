.input-hour-container {
  max-width: 134px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
}

.input-hour-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 0px;
  margin-right: 16px;

  color: #c8c8c8;
}

.input-hour-time-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 0px;
  margin-right: 16px;

  color: #280659;
}

.input-hour-time-label-modal {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  margin: 8px 16px 8px 16px;
  text-align: center;
  cursor: pointer;

  color: #280659;
}

.input-hour-absolute-container {
  position: absolute;
  width: 134px;
  z-index: 100;
}

.input-hour-modal-container {
  width: 80px;
  height: 250px;
  top: 12px;
  left: 30px;
  border-radius: 10px;
  display: block;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  scroll-behavior: smooth;
}

/*
  Scroll bar styling
*/
.input-hour-modal-container::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
}

.input-hour-modal-container::-webkit-scrollbar-track {
  opacity: 0;
}

.input-hour-modal-container::-webkit-scrollbar-thumb {
  background-color: #422680; /* color of the scroll thumb */
  border-radius: 10px;
}

@media screen and (max-width: 470px) {
  .input-hour-container {
    margin-left: 8px;
  }

  .input-hour-label {
    font-size: 18px;
    line-height: 21px;
    margin-right: 6px;
  }

  .input-hour-time-label {
    font-size: 18px;
    line-height: 21px;
    margin-right: 4px;
  }

  .input-hour-time-label-modal {
    font-size: 18px;
    line-height: 21px;
    margin: 8px 16px 8px 16px;
  }
}
