.profile-de-ce-container {
  width: 994px;
  height: 400px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.profile-de-ce-header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  justify-self: flex-start;
  margin: 32px;
  color: #280659;
}

.profile-de-ce-h-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 32px 32px 32px;
}

.profile-de-ce-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 270px;
}

.profile-de-ce-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #280659;
  margin-top: 32px;
}

.profile-de-ce-label.sublabel {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #43256e;
  margin-top: 16px;
}

@media screen and (max-width: 470px) {
  .profile-de-ce-container {
    width: 350px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }

  .profile-de-ce-h-container {
    display: flex;
    flex-direction: column;
    margin: 16px;
  }
  .profile-de-ce-inner-container {
    margin-bottom: 16px;
  }
}
