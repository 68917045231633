.selectGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 470px) {
  .selectGroup {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
