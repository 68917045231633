.input-date-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 380px;
  height: 60px;
}

.input-date-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.input-date-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 0px;
  color: #280659;
}

@media screen and (max-width: 470px) {
  .input-date-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    height: 60px;
    margin-top: 16px;
  }

  .input-date-text {
    font-size: 16px;
    line-height: 18px;
  }
}
