.container {
  display: flex;
  flex-direction: row;
  margin-top: 48px;
}

.buttonImg {
  width: 30px;
  height: 30px;
  margin-right: 16px;
  cursor: pointer;
}

/* Mobile */
@media screen and (max-width: 470px) {
  .container {
    margin-top: 48px;
    margin-bottom: 16px;
    justify-content: center;
  }
}