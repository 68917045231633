.selectContainer {
  display: flex;
  flex-direction: row;
  margin-right: 4rem;

  font-family: 'Roboto';
  font-style: normal;
}

.labelInputSelect {
  width: 53px;
  height: 21px;
  left: 32px;
  top: 110px;

  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  align-self: center;

  color: #280659;
}

.container {
  background: #ffffff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 16px 32px 16px 32px;

  font-family: 'Roboto';
  font-style: normal;
}

.infoCell {
  width: 33%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 16px 0px;
}

@media screen and (max-width: 470px) {
  .selectContainer {
    margin-right: 0px;
    margin-bottom: 1rem;
  }

  .container {
    flex-direction: column;
    padding-bottom: 0px;
  }

  .infoCell {
    width: 100%;
  }
}
