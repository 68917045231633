.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox{
  min-width: 18px;
  min-height: 18px;
  background: #ffffff;
  margin-right: 6px;
}
