.onboarding-email-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 350px;
}

.onboarding-email-header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  color: #280659;
  margin-bottom: 16px;
  margin-top: 16px;
}

.onboarding-email-email {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #280659;
  margin-top: 16px;
  margin-bottom: 16px;
}

.onboarding-email-email.underline {
  text-decoration: underline;
}

.onboarding-email-email.underline-clickable {
  text-decoration: underline;
  cursor: pointer;
}

.onboarding-email-loading {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  border: 4px solid rgba(40, 6, 89, 0.3);
  border-top-color: #280659;
  animation: rotation 1s infinite linear;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (max-width: 470px) {
  .onboarding-email-header {
    font-size: 20px;
    line-height: 23px;
  }
}
