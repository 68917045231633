.date-picker-modal-absolute-container {
  position: absolute;
  width: 200px;
}

.date-picker-modal-container {
  width: 320px;
  top: 12px;
  left: -5px;
  border-radius: 10px;
  display: block;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.date-picker-modal-inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.date-picker-modal-month-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.date-picker-modal-month-button {
  border-radius: 100px;
  padding: 8px;
  cursor: pointer;
}

.date-picker-modal-month-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.date-picker-modal-month-button.left {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.date-picker-modal-month-button.right {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.date-picker-modal-month-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 25px;
  text-transform: capitalize;
  /* identical to box height */

  color: #280659;
  margin-bottom: 0px;
}

.date-picker-modal-calendar {
  width: 100%;
  margin-bottom: 8px;
}

.date-picker-modal-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.date-picker-modal-bubble-button {
  width: 125px;
  height: 30px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 100px;
  padding: 3px;
  cursor: pointer;
  color: #464646;
}

.date-picker-modal-bubble-button:hover {
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.2);
}

.date-picker-modal-bubble-button.confirm {
  box-shadow: 0px 4px 16px 0px rgba(40, 6, 89, 0.2);
  color: #280659;
}

.date-picker-modal-bubble-button.confirm:hover {
  box-shadow: 0px 6px 16px 0px rgba(40, 6, 89, 0.3);
}
