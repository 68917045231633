.mainContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 32px;
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  width: 367px;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0px;
}

.headerText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 0px;
  
  color: #280659;
}

.sectionText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 0px;
  color: #280659;
}

.normalText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0px;

  color: #280659;
}

.notificationLineContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}

.notificationLineInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.notificationIcon {
  margin-right: 8px;
}

.checkbox {
  width: 20px;
  height: 20px;

  background: #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
}

.timeBetweenContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.paymentLineContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 120px;
}

.paymentLineContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.scheduleLineContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.scheduleLineDayContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 88px;
}

.scheduleLineTaxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 154px;
}

.headerText {
  color: #280659;
  margin-bottom: 16px;
}

.extraScheduleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
  width: 150px;
}

.tooltipText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  align-self: flex-start;
  color: #280659;
  margin-bottom: 16px;
}

.modalContainer {
  width: 380px;
  background-color: white;
  border-radius: 10px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}