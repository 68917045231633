.input-field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-field-container.phone {
  width: 300px;
}

.input-field-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 8px;
  color: #280659;
  align-self: flex-start;
}

.input-field-label.center {
  align-self: center;
}

.input-field-label.bold {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}

.input-field-label.error {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0px;
  color: #f54952;
  align-self: flex-start;
  margin-left: 16px;
  margin-top: 2px;
}

.input-field-label.transparent {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0px;
  color: transparent;
  align-self: flex-start;
  margin-left: 16px;
  margin-top: 2px;
}

.input-field-input {
  border: 1px solid #280659;
  box-sizing: border-box;
  height: 32px;
  width: 100%;
  border-radius: 100px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  
}

.input-field-input.invalid {
  border: 1px solid #f54952;
}

.input-field-input.center {
  text-align: center;
}

@media screen and (max-width: 470px) {
  .input-field-label {
    margin-bottom: 8px;
  }

  .input-field-label {
    font-size: 18px;
    line-height: 21px;
  }

  .input-field-label.bold {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
}
