.search-form-location-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.search-form-location-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.search-form-location-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #280659;
}

.search-form-location-modal-outer-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-form-location-modal-container {
  width: 256px;
  max-height: 250px;
  top: 0px;
  border-radius: 10px;
  display: block;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  scroll-behavior: smooth;
  z-index: 100;
}

.search-form-location-modal-inner-container {
  width: 238px;
  height: 250px;
  top: 100px;
  margin-top: 0px;
  display: block;
}

/*
  Scroll bar styling
*/
.search-form-location-modal-container::-webkit-scrollbar {
  width: 2px; /* width of the entire scrollbar */
}

.search-form-location-modal-container::-webkit-scrollbar-track {
  opacity: 0;
}

.search-form-location-modal-container::-webkit-scrollbar-thumb {
  background-color: #422680; /* color of the scroll thumb */
  border-radius: 10px;
}

@media screen and (max-width: 470px) {
  .search-form-location-container {
    margin-top: 24px;
    width: 90%;
  }

  .search-form-location-text {
    font-size: 16px;
    line-height: 18px;
  }
}
