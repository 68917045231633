.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gridline {
  height: 100%;
  width: 0.5px;
  background-color: rgba(66, 38, 128, 0.2);;
}