.onboarding-phone-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 350px;
}

.onboarding-phone-header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #280659;
  margin-bottom: 48px;
  margin-top: 32px;
}

.onboarding-phone-validate-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.onboarding-phone-edit-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.onboarding-phone-validate-action {
  margin-top: 32px;
  margin-bottom: 32px;
  width: 220px;
}

.PhoneInputInput {
  border-radius: 100px;
  height: 48px;
  padding-left: 16px;
  border: 1px solid #280659;
}

.PhoneInputCountrySelectArrow {
  color: #280659;
}

.onboarding-phone-edit-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #280659;
  margin-bottom: 0px;
  margin-right: 8px;
  text-align: center;
}

.onboarding-phone-edit-label.underline {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 0px;
}

.onboarding-phone-resend-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #280659;
  cursor: pointer;
}

.onboarding-phone-resend-label.disabled {
  opacity: 0.5;
  color: grey;
  cursor: default;
}

.onboarding-phone-resend-label:hover {
  text-decoration: underline;
}

.onboarding-phone-resend-label.disabled:hover {
  text-decoration: none;
}

@media screen and (max-width: 470px) {
  .onboarding-phone-edit-label {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 8px;
    margin-right: 8px;
    text-align: center;
  }

  .onboarding-phone-header {
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .onboarding-phone-edit-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    flex-wrap: wrap;
  }
}
