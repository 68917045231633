.onboarding-progress-container {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
  margin-bottom: 42px;
}

.onboarding-progress-bubble-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
}

.onboarding-progress-bubble {
  height: 50px;
  width: 50px;
  background: #ffffff;
  border: 1px solid #bfb4cd;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.onboarding-progress-bubble-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #c8c8c8;
  margin-bottom: 0px;
  margin-top: 10px;
}

.onboarding-progress-bubble-label.active {
  color: #280659;
}

.onboarding-progress-line {
  height: 1px;
  width: 50px;
  background: #bfb4cd;
  margin-bottom: 25px;
}

.onboarding-progress-line.complete {
  height: 3px;
  background: #422680;
}

.onboarding-progress-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 41px;
  text-align: center;
  color: #280659;
  margin-bottom: 0px;
}

@media screen and (max-width: 470px) {
  .onboarding-progress-container {
    height: 60px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .onboarding-progress-bubble-container {
    width: 30px;
  }

  .onboarding-progress-bubble-complete-icon {
    width: 30px;
    height: 30px;
  }

  .onboarding-progress-bubble {
    height: 30px;
    width: 30px;
  }

  .onboarding-progress-bubble-label {
    font-size: 12px;
    line-height: 14px;
  }

  .onboarding-progress-line {
    width: 25px;
  }

  .onboarding-progress-label {
    font-size: 18px;
    line-height: 21px;
  }
}
