.paragraph {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #280659;
}

.bold {
  font-weight: 700;
}

.underline {
  text-decoration-line: underline;
  cursor: pointer;
}

.textAlign {
  text-align: center;
}