.profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-inner-container {
  width: 1360px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 48px;
  margin-top: 48px;
}

.profile-main-container {
  width: 994px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 470px) {
  .profile-inner-container {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }

  .profile-main-container {
    width: 95%;
  }
}
