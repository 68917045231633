.legal-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.legal-container-width {
  width: 900px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.legal-container-paragraph {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
}

.legal-container-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  color: #000000;
  align-self: flex-start;
}

.legal-container-text.bold {
  font-weight: 600;
}

.legal-container-text.sub-header {
  font-size: 24px;
  line-height: 27px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 600;
}

.legal-container-text.header {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 48px;
  align-self: center;
}

@media screen and (max-width: 470px) {
  .legal-container-width {
    width: 90%;
    margin-top: 32px;
  }
}
