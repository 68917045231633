.smallButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 8px 16px 8px 12px;
  cursor: pointer;

  height: 32px;

  background: #FFFFFF;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.07);
  border-radius: 100px;
}

.smallButton:hover {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}