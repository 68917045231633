.container {
  display: flex;
  flex-direction: row;

  padding: 16px 40px;
  margin-bottom: 8px;

  background: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
}

.column {
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: center;
}

.image {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.width15 {
  width: 15%;
}

.width25 {
  width: 25%;
}