.login-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.login-form-headline {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: #280659;
  margin-top: 48px;
  margin-bottom: 32px;
}

.login-form-separator {
  width: 382px;
}

.login-form-input-container {
  width: 382px;
  margin-top: 32px;
}

.login-form-forget-password {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #280659;
  cursor: pointer;
  margin-top: 32px;
  margin-bottom: 0px;
}

.login-form-accept-terms-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 0px;
}

.login-form-accept-terms-checkbox {
  width: 18px;
  height: 18px;

  background: #ffffff;
  border: 1px solid #422680;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 6px;
}

.login-form-accept-terms-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #280659;
  margin-bottom: 0px;
}

.login-form-accept-terms-label.red {
  text-decoration-line: underline;
  color: #f65f68;
  cursor: pointer;
}

.login-form-action-button-container {
  width: 264px;
  margin-top: 48px;
  margin-bottom: 48px;
}

.login-form-no-account-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #280659;
}

.login-form-no-account-label.red {
  text-decoration-line: underline;
  color: #f65f68;
  cursor: pointer;
}

@media screen and (max-width: 470px) {
  .login-form-headline {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .login-form-separator {
    width: 100%;
  }

  .login-form-input-container {
    width: 100%;
    margin-top: 16px;
  }

  .login-form-forget-password {
    margin-top: 16px;
  }

  .login-form-accept-terms-container {
    margin-top: 16px;
  }

  .login-form-action-button-container {
    width: 160px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .login-form-no-account-label {
    margin-bottom: 48px;
  }
}
