.car-page-details-card {
  display: flex;
  flex-direction: column;
  width: 444px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  padding: 32px 32px 16px 32px;
  margin-bottom: 16px;
}

.car-page-details-card:hover {
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.12);
}

.car-page-details-inner-container.bottom {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.car-page-search-container {
  width: 100%;
}

.car-page-details-action-button {
  width: 250px;
  align-self: center;
  margin-bottom: 16px;
  margin-top: 32px;
}

.car-page-price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.car-page-prices-table-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 12px;
  cursor: pointer;

  width: 130px;
  height: 32px;

  background: #FFFFFF;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.car-page-prices-table-button:hover {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}

.car-page-prices-table-button-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0px;
  margin-left: 8px;

  color: #280659;
}

.car-page-details-card-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 32px;
  color: #280659;
}

.car-page-details-card-text.total {
  font-size: 20px;
  line-height: 23px;
  color: #280659;
}

.car-page-details-card-text.normal {
  font-family: Roboto;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}

.car-page-details-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 22px;
  margin-top: 16px;
}

.car-page-details-separator {
  margin-top: 16px;
}

@media screen and (max-width: 470px) {
  .car-page-details-card {
    width: 100%;
  }

  .car-page-details-card-text {
    font-size: 25px;
    line-height: 29px;
    margin-bottom: 0px;
  }
}
