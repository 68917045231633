.profile-data-container-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  width: 250px;
  height: 100%;
}

.profile-data-complation {
  width: 110px;
  height: 110px;
  border-radius: 100px;
  border: 11px solid rgba(40, 6, 89, 0.3);
  transform: rotate(45deg);
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile-data-complation.progress25 {
  border-top-color: #280659;
}

.profile-data-complation.progress50 {
  border-top-color: #280659;
  border-right-color: #280659;
}

.profile-data-complation.progress75 {
  border-top-color: #280659;
  border-right-color: #280659;
  border-bottom-color: #280659;
}

.profile-data-complation.progress100 {
  border: 11px solid #60c72e;
}

.profile-data-right-header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #280659;
  margin-bottom: 32px;
  margin-top: 16px;
}

.profile-data-right-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #280659;
  margin-top: 32px;
  margin-bottom: 0px;
}

.profile-data-right-success-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #280659;
  width: 205px;
  margin-bottom: 16px;
}

.profile-data-right-progress {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #280659;
  margin-bottom: 0px;
  transform: rotate(-45deg);
}
