.container {
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px 51px 32px;
  position: relative;
}

.inputDateContainer {
  margin-top: 11px;
}

.clearButton {
  position: absolute;
  bottom: 32px;
  right: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.containerCompact {
  flex-direction: column;
  padding-bottom: 32px;
}

@media screen and (max-width: 470px) {
  .container {
    flex-direction: column;
    padding-bottom: 0px;
  }

  .clearButton {
    position: static;
    cursor: pointer;
    margin: 32px 0;
  }
}