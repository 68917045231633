.infoCellHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  height: 16px;

  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  margin-right: 0.5rem;
  white-space: nowrap;

  color: #280659;
}
