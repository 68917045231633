.container {
  position: relative;
}

.container:last-child {
  margin-bottom: 0px;
}

.textContainer {
  display: inline;
  font-size: 14px;
}

.headerText {
  color: #280659;
  margin-bottom: 16px;
}

.tooltipText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #280659;
  margin-bottom: 16px;
}

.modalContainer {
  width: 380px;
  background-color: white;
  border-radius: 10px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 470px) {
  .thumbnail {
    width: 100%;
    margin-right: 0px;
    height: auto;
    aspect-ratio: 2;
    margin-top: 16px;
  }
  
  .textContainer {
    width: 100%;
    display: block
  }

  .centerTextOnMobile {
    text-align: center;
  }
}
