.profile-data-container {
  width: 994px;
  margin-bottom: 16px;
  padding: 32px;
  padding-bottom: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.profile-data-header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  color: #280659;
  margin-bottom: 16px;
}

.profile-data-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
  height: 266px;
}

.profile-data-container-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 16px;
}

.profile-data-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
}

.profile-data-row.center {
  align-items: center;
}

.profile-data-row-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #280659;
  margin-bottom: 0px;
  width: 290px;
}

.profile-data-row-label.auto {
  width: auto;
  margin-right: 16px;
}

.profile-data-row-label.bold {
  font-weight: 700;
  width: 170px;
}

.profile-data-row-status-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px 8px 16px;
  background: #ffffff;
  border: 1px solid #280659;
  box-sizing: border-box;
  border-radius: 100px;
  box-sizing: border-box;
  margin-left: 16px;
}

.profile-data-row-status-dot {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #60c72e;
}

.profile-data-row-status-dot.orange {
  background-color: #ff8a00;
}

.profile-data-row-status-dot.red {
  background-color: #ff0000;
}

@media screen and (max-width: 470px) {
  .profile-data-container {
    width: 350px;
  }

  .profile-data-inner-container {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .profile-data-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 32px;
  }

  .profile-data-row.center {
    align-items: flex-start;
  }

  .profile-data-row-status-container {
    margin-left: 0px;
  }
}
