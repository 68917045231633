.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.checkoutLeft {
  width: 648px;
  padding: 48px 32px 64px 32px;
}

.checkoutRight {
  width: 460px;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.carImg {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
}

.carRow {
  display: flex;
  flex-direction: row;
}

.carColumn {
  display: flex;
  flex-direction: column;
}

.carNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex: 1;
}

.carCategoryBanner {
  width: 100%;
  height: 40px;

  background: #422680;
  color: #ffffff;

  border-radius: 10px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryBannerImage {
  position: absolute;
  right: 0;

  margin-left: 8px;
  border-radius: 10px;

  width: 102px;
  height: 40px;
}

.carImagePreviewContainer {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.carPreview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  color: #280659;
}

.carImage {
  width: 116px;
  height: 90px;

  margin-bottom: 8px;
  border-radius: 10px;
}

.carPreviewSplitter {
  background-color: rgba(245, 73, 82, 0.3);
  width: 1px;
  margin: 0 6px;
}

/* Mobile */
@media screen and (max-width: 470px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .checkoutLeft {
    width: 100%;
    padding: 16px;
  }

  .checkoutRight {
    width: 95%;
    margin: 0 auto;
    padding: 16px;
    background: #ffffff;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  .carImage {
    width: 100px;
    height: 80px;
  }

  /*
  
  .carImg {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .carRow {
    display: flex;
    flex-direction: row;
  }
  
  .carNameContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex: 1;
  } */
}
