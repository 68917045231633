.block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 474px;
  height: 290px;

  margin-top: 32px;
  padding: 32px;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.block:hover {
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.09);
}

.columnContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 144px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.smallButton:hover {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}