.infoCellBody {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 1rem;
}

.bodyText {
  color: #280659;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  margin-right: 0.5rem;
}

.statisticsText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;

  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.redColor {
  color: #f54952;
}

.greenColor {
  color: #60c72e;
}
