
.cardContainer {
  box-sizing: border-box;
  padding: 16px;
  border: 1.5px solid #CFCFCF;
  border-radius: 10px;
  margin-bottom: 8px;
  cursor: pointer;
  position: relative;
}

.cardContainerSelected {
  border: 1.5px solid #422680;
}

.cardContainerUnavailable {
  border: 1.5px solid #CFCFCF;
  cursor: inherit;
}

.cardOptionPrice {
  position: absolute;
  top: 16px;
  right: 64px;
}

.cardOptionTick {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}
