.container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 99;
}

.innerContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.overlay {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.content {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerContent {
  position: relative;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 5px;
  font-size: 32px;
  padding: 5px;
  cursor: pointer;
}