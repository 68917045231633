.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 980px;
  height: 148px;

  padding: 32px;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 370px;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 370px;
}

.sumContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 370px;
}

.alignRight {
  align-self: flex-end;
}

.headerText {
  color: #280659;
  margin-bottom: 16px;
}

.tooltipText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  align-self: flex-start;
  color: #280659;
  margin-bottom: 16px;
}

.modalContainer {
  width: 380px;
  background-color: white;
  border-radius: 10px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
