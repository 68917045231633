.container {
  display: flex;
  flex-direction: row;
}

.tabItem {
  background: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 100px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #422680;

  padding: 9px 16px;
  margin-right: 32px;
  cursor: pointer;
}

.activeTabItem {
  background: #422680;
  color: #FFFFFF;
}

@media screen and (max-width: 470px) {
  .container {
    justify-content: space-around;
  }

  .tabItem {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 100px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px; 
    line-height: 16px;
    color: #422680;

    padding: 7px 16px;
    margin-right: 0px;
    cursor: pointer;
  }

  .activeTabItem {
    background: #422680;
    color: #FFFFFF;
  }
}
