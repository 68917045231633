.mainContainer {
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;
  width: 482px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
}

.textContainer {
  height: 146px;
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.imgContainer {
  height: 148px;
  width: 148px;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 8px;
  text-decoration-line: underline;
  cursor: pointer;
  color: #280659;
}

.registerPlate {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  color: #280659;
}

.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;

  color: #000000;
}

.total {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 0px;
  color: #280659;
}

.redLabel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  color: #F6545D;
  margin-top: 16px;
  margin-bottom: 0px;
}