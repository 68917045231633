.container {
  background: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 16px;
}

.containerHover {
  background: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 16px;
}

.containerHover:hover {
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.09);
}