.linkText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #280659;
  cursor: pointer;
  margin-top: 32px;
  margin-bottom: 0px;
}

.destructive {
  color: #F54952
}

.center {
  text-align: center;
}