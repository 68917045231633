.container {
  height: 90%;
  width: 1px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: rgba(245, 73, 82, 0.3);
  align-self: center;
}

.fullHeight {
  height: 100%;
}