.selectContainer {
  display: flex;
  flex-direction: row;
  margin-right: 4rem;

  font-family: 'Roboto';
  font-style: normal;
}

.labelInputSelect {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  align-self: center;

  color: #280659;
}

.chartContainer {
  background: #ffffff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  padding: 16px 16px 16px 16px;

  font-family: 'Roboto';
  font-style: normal;
}
