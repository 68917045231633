.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 32px;
  margin-bottom: 16px;
}

.thumbnail {
  width: 292px;
  height: 164px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 32px;
}

.rightContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.detailsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.contactDetailsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.dateContainer {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 470px) {
  .container {
    padding: 16px;
    flex-direction: column-reverse;
  }
  
  .thumbnail {
    width: 100%;
    margin-right: 0px;
    height: auto;
    aspect-ratio: 2;
    margin-top: 16px;
  }

  .alignFlexEnd {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
