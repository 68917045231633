.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #422680;
  box-shadow: 0px 4px 15px rgba(66, 38, 128, 0.6);
  border-radius: 10px;
  color: white;
  text-align: center;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  padding: 11px 24px;
  cursor: pointer;
}

.action-button-disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}

.button-invisible-spinner {
  visibility: hidden;
}

.button:hover {
  box-shadow: 0px 6px 20px rgba(66, 38, 128, 0.8);
}

@media screen and (max-width: 470px) {
  .button {
    background-color: #422680;
    box-shadow: 0px 4px 15px rgba(66, 38, 128, 0.6);
    border-radius: 10px;
    color: white;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    padding: 9px 15px;
  }
}
